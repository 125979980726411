.confirmation-captain-modal{
  height: 444px!important;
}
.confirmation-captain-validators-modal{
  height: 444px!important;
}

.card-cap-new {
    background-color: #0d2744;
    border-radius: 10px;
    padding: 20px;
    width: 310px;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    
    .card-header-cap-new {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
  
      .avatar-cap-new {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
      }
  
      .user-info-cap-new {
        .name-cap-new {
          font-size: 18px;
          font-weight: bold;
          margin: 0;
        }
  
        .role-cap-new {
          font-size: 14px;
          color: #b0b0b0;
        }
      }
    }
  
    .card-body-cap-new {
      .info-row-cap-new {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
.span-cap-new {
        background: #0f1b2b;
        width: 261px;
        display: flex;
        border-radius: 5px;
        height: 30px;
        align-items: center;
        justify-content: flex-start;
        padding-left: 13px;

  }
        .check-cap-new {
            color: #0063dd;
            font-size: 18px;
            background: #0f1b2b;
            width: 31px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
        }
  
        .thanks-count-cap-new {
          font-weight: bold;
          font-size: 16px;
        }
      }
  
      .validators-btn-cap-new {
        background-color: #0066cc;
        color: white;
        border: none;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
  
        &:hover {
          background-color: #004c99;
        }
      }
    }
  }
  



  .validator-list-cap {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    margin-top: 87px;
    margin-bottom: 44px;
  }
  
  .validator-item-cap {
    display: flex;
    align-items: center;
   
    color: #ffffff;
    font-size: 16px;
  
    .checkbox-cap {
      width: 20px;
      height: 20px;
      background-color: #1b2430;
    
      margin-right: 15px;
      border-radius: 4px;
    }
  
    .validator-name-cap {
        font-size: 14px;
        color: #ffffff;
        background:#1b2430;
        width: 227px;
        height: 81x !important;
        border-radius: 5px;
        padding: 10px;
    }
  
    &:hover {
      background-color: #242d3c;
    }
  }
  


  .profile-card-cap {
   
    color: #ffffff;
    width: 300px;
    border-radius: 10px;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  
    .profile-header-cap {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
  
      .profile-pic-cap {
        width: 242px;
        display: flex;
        /* justify-content: space-between; */
        /* align-content: center; */
        /* align-items: baseline; */
        /* flex-wrap: wrap; */
        margin-left: 27px;
      }
  
      .profile-info-cap {
        .profile-name-cap {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 5px;
        }
  
        .profile-info-desc-cap {
          font-size: 12px;
          color: #b0b0b0;
        }
      }
    }
  
    .profile-details-cap {
      margin-bottom: 20px;
  
      .profile-detail-item-cap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color:#0f1b2b;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
  
        .label-cap {
          font-weight: bold;
        }
  
        .info-cap {
          color: #b0b0b0;
        }
  
        .edit-icon-cap {
          cursor: pointer;
        }
      }
    }
  
    .confirmation-section-cap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color:#0f1b2b;
      padding: 10px;
      border-radius: 5px;
  
      p {
        margin: 0;
        font-size: 14px;
      }
  
      .switch-cap {
        position: relative;
        display: inline-block;
        width: 54px;
        height: 29px;
  
        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
  
        .slider-cap {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color:#202d47;
          transition: 0.4s;
          border-radius: 34px;
  
          &.round-cap {
            border-radius: 34px;
          }
  
          &::before {
            position: absolute;
            content: "";
            height: 24px;
            width: 24px;
            left: 3px;
            bottom: 3px;
            background-color: white;
            transition: 0.4s;
            border-radius: 50%;
          }
        }
  
        input:checked + .slider-cap {
          background-color: #00d100;
        }
  
        input:checked + .slider-cap::before {
          transform: translateX(14px);
        }
      }
    }
  }
  

.cap-new-card{
  max-width: 347px;
  width: 347px;
  border-radius: 12px;
}

.phrase-container-height{
  height: 90px !important;
  margin-bottom: 0px !important;
  margin: 0;
}
.book-top{
  top: -33px !important;
}

.captain-card{
  width: 323px!important;
  max-width: 323px!important;
}